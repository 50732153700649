<section class="section row">
  <img class="col-lg-4 offset-lg-1" src="../../../../assets/img/besoinClient.png" alt="Nuage de mots sur les besoins client"/>
  <div class="col-lg-5">
    <h2>Renforcez votre équipe et sécurisez vos projets</h2>
    <p>
      Que vous soyez une ESN, une PME ou une grande entreprise, dans de nombreuses circonstances vous êtes amené à manquer de ressources et nécessitez une personne supplémentaire.<br/>
      Les raisons peuvent être variées :
    </p>
    <ul>
      <li>Gérer les évolutions au sein d'un projet existant</li>
      <li>Vous lancer dans de nouveaux projets</li>
      <li>Améliorer vos process de test et de livraison</li>
      <li>Faire face à des imprévus, des obligations réglementaires...</li>
      <li>Et bien d'autres...</li>
    </ul>
    <p>
      Faire appel à un développeur freelance, c'est se donner le coup de pouce dont vous avez souvent besoin pour mener à bien vos projets.
    </p>
  </div>
</section>

