<div class="container-fluid">
  <section class="section row flex-column">
    <h2>Comment je suis arrivé ici :</h2>
    <div class="container col-8 offset-2">
      <div class="my-timeline">
        <div class="timeline" style="border-top: 0px">
        </div>
        <div class="timeline timeline-right color-gold mt-5">
          <span class="icon fa fa-rocket"></span>
          <div class="timeline-content">
            <div class="ml-2"><em>Janvier 2020</em></div>
            <p class="mb-0"><strong>Freelance :</strong> Début de mon activité en micro-entrepreneur.</p>
          </div>
        </div>
        <div class="timeline timeline-left color-cocktail">
          <span class="icon fa fa-briefcase"></span>
          <div class="timeline-content">
            <div class="d-flex justify-content-end mr-3 mb-2"><em>Janvier 2018 à Décembre 2019</em></div>
            <strong>Ingénieur d’études à Association Cocktail, Talence</strong>
            <p class="mb-0">
              •	Développement d’une application de paye à façon.<br/>
              •	Refonte d’applications existantes.<br/>
              •	Mise en place d’environnements conteneurisés.<br/>
              •	Ajout d’une communication asynchrone entre applications via Kafka.
            </p>
            <div>
              <span class="badge badge-pill badge-primary mr-1">Java</span>
              <span class="badge badge-pill badge-primary mr-1">Spring</span>
              <span class="badge badge-pill badge-primary mr-1">Spring boot</span>
              <span class="badge badge-pill badge-primary mr-1">Hibernate</span>
              <span class="badge badge-pill badge-primary mr-1">Gradle</span>
              <span class="badge badge-pill badge-primary mr-1">Oracle</span>
              <span class="badge badge-pill badge-primary mr-1">Jenkins</span>
              <span class="badge badge-pill badge-primary mr-1">Angular</span>
              <span class="badge badge-pill badge-primary mr-1">RxJs</span>
              <span class="badge badge-pill badge-primary mr-1">Bootstrap</span>
              <span class="badge badge-pill badge-primary mr-1">Docker</span>
              <span class="badge badge-pill badge-primary mr-1">Kafka</span>
              <span class="badge badge-pill badge-primary mr-1">Avro</span>
              <span class="badge badge-pill badge-primary mr-1">Git</span>
              <span class="badge badge-pill badge-primary">Scrum</span>
            </div>
          </div>
        </div>
        <div class="timeline timeline-right mt-100">
          <span class="icon fa fa-graduation-cap"></span>
          <div class="timeline-content">
            <div class="ml-2"><em>Avril 2018</em></div>
            <p class="mb-0"><strong>Formation :</strong> Angular (3 jours) par Ambient IT.</p>
          </div>
        </div>
        <div class="timeline timeline-right color-darkgray">
          <span class="icon fa fa-graduation-cap"></span>
          <div class="timeline-content">
            <div class="ml-2"><em>Février 2018</em></div>
            <p class="mb-0"><strong>Formation :</strong> L’essentiel pour maîtriser Docker (3 jours) par Treeptick.</p>
          </div>
        </div>
        <div class="timeline timeline-left color-sopra">
          <span class="icon fa fa-briefcase"></span>
          <div class="timeline-content">
            <div class="d-flex justify-content-end mr-3 mb-2"><em>Février 2015 à Janvier 2018</em></div>
            <strong>Ingénieur d’études à SOPRA STERIA, Mérignac</strong>
            <p class="mb-0">
              •	Développement et maintien en condition d’opération d’une application web.<br/>
              •	Migration technique (Monté de version Java, Oracle, changement d’OS…).<br/>
              •	Industrialisation de SI, automatisation du process d’installation.
            </p>
            <div>
              <span class="badge badge-pill badge-primary mr-1">Java</span>
              <span class="badge badge-pill badge-primary mr-1">Spring</span>
              <span class="badge badge-pill badge-primary mr-1">Struts</span>
              <span class="badge badge-pill badge-primary mr-1">Oracle</span>
              <span class="badge badge-pill badge-primary mr-1">Apache</span>
              <span class="badge badge-pill badge-primary mr-1">Jenkins</span>
              <span class="badge badge-pill badge-primary mr-1">Maven</span>
              <span class="badge badge-pill badge-primary">SVN</span>
            </div>
          </div>
        </div>
        <div class="timeline timeline-right mt-100">
          <span class="icon fa fa-graduation-cap"></span>
          <div class="timeline-content">
            <div class="ml-2"><em>Octobre 2017</em></div>
            <p class="mb-0"><strong>Formation :</strong> Java testing (3 jours) par Sopra Académie.</p>
          </div>
        </div>
        <div class="timeline timeline-right color-darkgray">
          <span class="icon fa fa-graduation-cap"></span>
          <div class="timeline-content">
            <div class="ml-2"><em>Juin 2016</em></div>
            <p class="mb-0"><strong>Formation :</strong> Principe des architectures Java EE (3 jours) par Sopra Académie</p>
          </div>
        </div>
        <div class="timeline timeline-left color-enseirb mt-5">
          <span class="icon fa fa-university"></span>
          <div class="timeline-content">
            <div class="d-flex justify-content-end mr-3 mb-2"><em>Septembre 2011 à Janvier 2015</em></div>
            <strong>Elève-ingénieur à l'ENSEIRB-MATMECA, Talence</strong>
            <p>Etudiant au sein de la filière informatique de l'ENSEIRB-MATMECA (Ecole Nationale Supérieure d’Electronique, Informatique, Télécommunications,
              Mathématique et Mécanique) de Bordeaux.</p>
          </div>
        </div>
        <div class="timeline timeline-right color-gold mt-5">
          <span class="icon fa fa-graduation-cap"></span>
          <div class="timeline-content">
            <div class="ml-2"><em>2015</em></div>
            <p class="mb-0"><strong>Diplôme d’ingénieur</strong> en informatique.</p>
          </div>
        </div>
        <div class="timeline timeline-right">
          <span class="icon fa fa-graduation-cap"></span>
          <div class="timeline-content">
            <div class="ml-2"><em>Juillet - Aout 2012</em></div>
            <p class="mb-0"><strong>Formation :</strong> Théorie des jeux et Management & Marketing (2 mois) à l’IHK</p>
          </div>
        </div>
        <div class="timeline timeline-left color-cpge mt-5">
          <span class="icon fa fa-university"></span>
          <div class="timeline-content">
            <div class="d-flex justify-content-end mr-3 mb-2"><em>Septembre 2009 à Juin 2011</em></div>
            <strong>Etudiant à Chateaubriand, Rennes</strong>
            <p>Etudiant en CPGE (Classe préparatoire aux grandes écoles) au lycée Chateaubriand à Rennes, filière PCSI/PSI.</p>
          </div>
        </div>
        <div class="timeline timeline-right color-darkgray mt-5">
          <span class="icon fa fa-graduation-cap"></span>
          <div class="timeline-content">
            <div class="ml-2"><em>2011</em></div>
            <p class="mb-0">Attestation de formation en CPGE, mention A.</p>
          </div>
        </div>
        <div class="timeline timeline-right color-gold">
          <span class="icon fa fa-graduation-cap"></span>
          <div class="timeline-content">
            <div class="ml-2"><em>2009</em></div>
            <p class="mb-0"><strong>Baccalauréat </strong> général scientifique, mention Bien.</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <app-profil></app-profil>
</div>
