<header>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div  class="col-lg-5 offset-1">
      <div class="navbar-brand d-flex" id="maNavbarBrand">
        <img class="rounded-circle" src="../../../assets/img/entetePhoto.jpg" alt="Ma photo d'entête" id="monEntetePhoto" />
        <span class="marginAuto">Freelance-Bordeaux</span>
      </div>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>
    <div  class="col-lg-3 offset-1">
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item active"><a class="nav-link" routerLink=""><span class="fa fa-home"></span></a></li>
          <li class="nav-item"><a class="nav-link" routerLink="parcours">Parcours</a></li>
          <li class="nav-item"><a class="nav-link" routerLink="portfolio">Portfolio</a></li>
          <li class="nav-item"><a class="nav-link" routerLink="contact">Contact</a></li>
        </ul>
      </div>
    </div>
  </nav>
</header>
