<section class="section row">
  <div class="col-lg-6 offset-1">
    <h2>Je peux vous aider</h2>
    <p>Ingénieur d'études, je vous propose de venir renforcer votre équipe pour des missions et projets à durée varié afin que vous puissiez :</p>
    <ul>
      <li>Accroître votre production</li>
      <li>Améliorer la qualité de vos livrables</li>
      <li>Découvrir la conteneurisation</li>
      <li>Adopter une démarche DevOps</li>
    </ul>
    <p>Je peux effectuer mes missions à distance mais aussi vous rejoindre afin de travailler au plus proche de votre équipe, dans vos locaux.<br/>
      Mes compétences me permettent d'intervenir sur vos projets composés des technologies suivantes :
    </p>
    <ul>
      <li><strong>Back-end</strong> : Java, Spring, Hibernate</li>
      <li><strong>Front-end</strong> : Angular, RxJS, Bootstrap</li>
      <li><strong>DevOps</strong> : Docker, Jenkins, Swarm</li>
    </ul>
  </div>
  <div class="col-lg-2 offset-1 text-center">
    <img src="../../../../assets/img/CV.jpg" class="cursor-zoom-in" alt="Mon CV mini format" data-toggle="modal" href="#infos"/>
    <div class="modal" id="infos">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-body">
            <img src="../../../../assets/img/CV.jpg" alt="Mon CV mini format" data-toggle="modal" href="#infos"/>
          </div>
          <div class="modal-footer justify-content-center">
            <a href="../../../../assets/img/CV_Le_Bot_Jerome.pdf" target="_blank">
              <button type="button" class="btn btn-sm btn-danger"><span class="fa fa-download"></span> Télécharger le CV au format pdf</button>
            </a>
          </div>
        </div>
      </div>
    </div>
    <a href="../../../../assets/img/CV_Le_Bot_Jerome.pdf" target="_blank">
      <button type="button" class="btn btn-sm btn-danger" id="btn-telecharger-cv"><span class="fa fa-download"></span> Télécharger le CV au format pdf</button>
    </a>
  </div>
  <div class="col-lg-9 offset-1 container">
    <div class="row" id="mesBadges">
      <span class="badge badge-pill badge-primary">Java</span>
      <span class="badge badge-pill badge-primary">Spring</span>
      <span class="badge badge-pill badge-primary">Spring boot</span>
      <span class="badge badge-pill badge-primary">Angular</span>
      <span class="badge badge-pill badge-primary">Docker</span>
      <span class="badge badge-pill badge-primary">Hibernate</span>
      <span class="badge badge-pill badge-primary">Bootstrap</span>
      <span class="badge badge-pill badge-primary">Oracle</span>
      <span class="badge badge-pill badge-primary">Maven</span>
      <span class="badge badge-pill badge-primary">Gradle</span>
      <span class="badge badge-pill badge-primary">Scrum</span>
      <span class="badge badge-pill badge-primary">Kafka</span>
      <span class="badge badge-pill badge-primary">Avro</span>
      <span class="badge badge-pill badge-primary">Git</span>
      <span class="badge badge-pill badge-primary">Docker-compose</span>
    </div>
  </div>
</section>
