<section class="section row">
  <div class="col-5 offset-2">
    <h2>À Propos</h2>
    <p>
      Passionné de science et de nouvelles technologies, j'ai rapidement opté pour une formation d'ingénieur, me spécialisant dans le domaine informatique.
    </p>
    <p>
      Tout le long de mes années d'activité en tant qu'ingénieur d'études au sein de l'ESN Sopra-Stéria, l'association Cocktail ainsi qu'en freelance,
      j'ai prêté une attention particulière à l'amélioration de mes compétences en participant notamment à des formations auprès d'organismes référencés.
    </p>
    <p>
      Ce besoin de toujours m'améliorer dans les domaines que j'affectionne m'amène aujourd'hui à avoir ma propre activité et proposer mes services en tant que freelance.
    </p>
  </div>
  <img class="col-2 offset-1" src="../../../../assets/img/monProfil.jpg" alt="Ma photo de profil"/>
</section>
