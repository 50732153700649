<section class="section row flex-column">
  <h2>Ce qu'ils disent de moi</h2>
  <div class="container rounded">
    <div id="recommandation-carousel" class="carousel slide" data-ride="carousel">
      <div class="carousel-inner">

        <div class="carousel-item active container">
          <div class="row mt-4 mb-4 h-fixed">
            <div class="col-3 d-flex justify-content-end align-items-center">
              <div>
                <img class="recommandationPhoto rounded-circle" src="assets/img/recommandations/paul.jpg"/>
              </div>
            </div>
            <div class="col-9 d-flex flex-column justify-content-center">
              <p class="recommandationText">
                « Jérôme est un bon élément, avec des qualités humaines qui lui permette de s'intégrer facilement en équipe.
                Il a un bon esprit critique et de très bonnes connaissances techniques.
                Il est curieux et force de proposition. Ce fut une très bonne collaboration... »
                <br/><br/>
                <strong>Paul Renier, Association Cocktail</strong>
                <a target="_blank" class="ml-2" href="https://www.linkedin.com/in/paul-renier/"><i class="fa fa-linkedin-square"></i></a>
              </p>
            </div>
          </div>
        </div>

        <div class="carousel-item container">
          <div class="row mt-4 mb-4 h-fixed">
            <div class="col-3 d-flex justify-content-end align-items-center">
              <div>
                <img class="recommandationPhoto rounded-circle" src="assets/img/recommandations/anne-laure.jpg"/>
              </div>
            </div>
            <div class="col-9 d-flex flex-column justify-content-center">
              <p class="recommandationText">
                « Jérôme n'est pas seulement un développeur compétent, il a également une communication toujours pertinente avec les clients et ses collaborateurs.
                Il a été ingénieur full stack JAVA dans la même équipe que moi, il était devenu référent Docker et était force de proposition sur des sujets hétérogènes,
                techniques comme fonctionnels.<br/>
                Il est un vrai atout dans une équipe et s'adaptera parfaitement à différents projets et contextes. »
                <br/><br/>
                <strong>Anne-Laure Gaillard, Association Cocktail</strong>
                <a target="_blank" class="ml-2" href="https://www.linkedin.com/in/annelauregaillard/"><i class="fa fa-linkedin-square"></i></a>
              </p>
            </div>
          </div>
        </div>

        <div class="carousel-item container">
          <div class="row mt-4 mb-4 h-fixed">
            <div class="col-3 d-flex justify-content-end align-items-center">
              <div class="d-flex flex-column justify-content-center">
                <img class="recommandationPhoto rounded-circle" src="assets/img/recommandations/thomas.png"/>
              </div>
            </div>
            <div class="col-9 d-flex flex-column justify-content-center">
              <p class="recommandationText">
                « Travailler avec Jérôme est un réel plaisir. C'est quelqu'un qui humainement est capable de s'adapter à n'importe quelle équipe,
                situation et qui techniquement réussi ce qu'il entreprend.
                <br/><br/>
                Son expertise, sa force de proposition et son engagement ont permis de faire des merveilles sur l'un de nos projet critique et de le faire sortir
                dans les délais impartis ainsi qu'avec les critères de qualité élevés attendus.
                <span *ngIf="fullText">
                  <br/><br/>
                  Avoir une personne comme Jérôme dans son équipe est un réel atout et je vous souhaite de pouvoir collaborer avec lui un jour.»
                </span>
                <button type="button" class="btn btn-link" (click)="onButtonClicked()">{{getButtonTitle()}}</button>
                <br/><br/>
                <strong>Thomas Vautrin, Orange</strong>
                <a target="_blank" class="ml-2" href="https://www.linkedin.com/in/thomas-vautrin-1805a529/"><i class="fa fa-linkedin-square"></i></a>
              </p>
            </div>
          </div>
        </div>
      </div>

      <a class="carousel-control-prev" href="#recommandation-carousel" data-slide="prev"><span class="fa fa-chevron-left fa-lg carousel-control"></span></a>
      <a class="carousel-control-next" href="#recommandation-carousel" data-slide="next"><span class="fa fa-chevron-right fa-lg carousel-control"></span></a>
    </div>
  </div>
</section>

