<section class="section row flex-column">
  <h2>Portfolio</h2>
  <div class="container rounded">
    <div id="portfolio-carousel" class="carousel slide" data-ride="carousel">
      <ul class="carousel-indicators">
        <li data-target="#portfolio-carousel" data-slide-to="0" class="active"></li>
        <li data-target="#portfolio-carousel" data-slide-to="1"></li>
        <li data-target="#portfolio-carousel" data-slide-to="2"></li>
        <li data-target="#portfolio-carousel" data-slide-to="3"></li>
        <li data-target="#portfolio-carousel" data-slide-to="4"></li>
        <li data-target="#portfolio-carousel" data-slide-to="5"></li>
        <li data-target="#portfolio-carousel" data-slide-to="6"></li>
      </ul>

      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src="../../../../assets/img/portfolio/cocktail/specialisations.png" alt="Liste electorale">
          <div class="carousel-caption d-none d-md-block">
            <p>Application de gestion d'une élection</p>
          </div>
        </div>
        <div class="carousel-item">
          <img src="../../../../assets/img/portfolio/cocktail/liste_electorale.png" alt="Liste electorale">
          <div class="carousel-caption d-none d-md-block">
            <p>Génération d'une liste électorale</p>
          </div>
        </div>
        <div class="carousel-item">
          <img src="../../../../assets/img/portfolio/cocktail/dossiers.png" alt="Liste electorale">
          <div class="carousel-caption d-none d-md-block">
            <p>Application de paye à façon</p>
          </div>
        </div>
        <div class="carousel-item">
          <img src="../../../../assets/img/portfolio/cocktail/import_en_cours.png" alt="Liste electorale">
          <div class="carousel-caption d-none d-md-block">
            <p>Imports de données externes</p>
          </div>
        </div>
        <div class="carousel-item">
          <img src="../../../../assets/img/portfolio/orange/dashboard.png" alt="Dashboard">
          <div class="carousel-caption d-none d-md-block">
            <p>Application de gestion de permissions</p>
          </div>
        </div>
        <div class="carousel-item">
          <img src="../../../../assets/img/portfolio/orange/console.png" alt="Console batch">
          <div class="carousel-caption d-none d-md-block">
            <p>Lancement manuel de batch</p>
          </div>
        </div>
        <div class="carousel-item">
          <img src="../../../../assets/img/portfolio/orange/habilitation.png" alt="Permissions">
          <div class="carousel-caption d-none d-md-block">
            <p>Validation de permissions</p>
          </div>
        </div>
      </div>

      <a class="carousel-control-prev" href="#portfolio-carousel" data-slide="prev"><span class="fa fa-chevron-left fa-lg carousel-control"></span></a>
      <a class="carousel-control-next" href="#portfolio-carousel" data-slide="next"><span class="fa fa-chevron-right fa-lg carousel-control"></span></a>
    </div>
  </div>
</section>
