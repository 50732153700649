import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notimplementedyet',
  templateUrl: './notimplementedyet.component.html',
  styleUrls: ['./notimplementedyet.component.scss']
})
export class NotimplementedyetComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
