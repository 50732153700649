<footer class="py-3 bg-light container-fluid d-flex justify-content-between">
  <div class="text-center">
    <h3>RESTONS CONNECTÉS</h3>
    <div>
      <div class="wrapper">
        <a target="_blank" href="https://www.facebook.com/FreelanceBdx"><i class="fa fa-2x fa-facebook-square" style="margin-left: 0px;"></i></a>
        <a target="_blank" href="https://www.linkedin.com/in/j%C3%A9r%C3%B4me-le-bot-6890564b/"><i class="fa fa-2x fa-linkedin-square"></i></a>
        <a target="_blank" href="https://www.instagram.com/freelance_bordeaux/"><i class="fa fa-2x fa-instagram"></i></a>
        <a target="_blank" href="https://twitter.com/FreelanceBdx"><i class="fa fa-2x fa-twitter-square"></i></a>
      </div>
    </div>
  </div>
  <div class="text-center">
    <!--<h3>INFORMATIONS</h3>-->
    <small>Contactez moi @ : jerome.lebot@freelance-bordeaux.fr<br/>&copy; Freelance-Bordeaux - Version 2021.4.00 du 26/10/2021</small>
  </div>
  <div class="text-center">
    <h3>ME TENIR INFORMÉ DES DISPONIBILITÉS</h3>
    <form action="https://freelance-bordeaux.us3.list-manage.com/subscribe/post?u=1a422ca515929434ecb763bf7&amp;id=1ef952f362"
          method="post" name="subscribe-form" target="_blank" class="form-inline" target="_blank" novalidate>
      <div class="container text-center">
        <div class="input-group">
          <input type="email" placeholder="votre@email.com" class="form-control form-control-sm" name="EMAIL" id="mce-EMAIL">
          <button class="btn btn-info btn-sm" type="submit" (click)="onSubscribe()" name="subscribe"><span class="fa fa-envelope"></span></button>
        </div>
      </div>
    </form>
  </div>
</footer>
